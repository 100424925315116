import "./footer.css";
const Footer = () => {
  return (
    <footer className="footer">
      <div>Developed by TripleTen student Adrian Mayfield </div>
      <div>2023</div>
    </footer>
  );
};
export default Footer;
